import { datadogLogs, HandlerType } from "@datadog/browser-logs"

const isProduction = location.href.startsWith("https://market.trck.jp")

datadogLogs.init({
  clientToken: "pub6b5e96ae94c2f7d2e07dbdb0dd766207",
  forwardErrorsToLogs: true
})
datadogLogs.addLoggerGlobalContext("service", "truckers")
datadogLogs.addLoggerGlobalContext("type", "browser")

// 本番以外はコンソールに出力
if (!isProduction) {
  datadogLogs.logger.setHandler(HandlerType.console)
}
